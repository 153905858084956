<template>
  <div class="d-flex flex-column px-5 px-md-10 pt-8">
    <!-- Tabs -->
    <v-tabs v-model="tab" color="secundary">
      <v-tabs-slider color="primary" />
      <v-tab v-for="{ id, name } in tabs" :key="id" class="text-capitalize" v-text="name" />
    </v-tabs>
    <v-divider />
    <!-- Search -->
    <v-row v-if="enrollments.length" class="tabs-toolbar align-self-sm-end justify-start mx-0 mb-12">
      <export-to-csv
        :file-name-preffix="`${project.name} - ${$t(`competition.sections.clubs`)}`"
        action-name="competition/getClubEnrollmentsCsv"
        :action-params="{ organizationId, projectId }"
        :button-text="`${$t(`common.export`)} ${$t(`competition.sections.clubs`)}`"
      />
      <export-to-csv
        :file-name-preffix="`${project.name} - ${$t(`common.teams`)}`"
        action-name="competition/getTeamEnrollmentsCsv"
        :action-params="{ organizationId, projectId }"
        :button-text="`${$t(`common.export`)} ${$t(`common.teams`)}`"
      />
      <span class="pr-4" />
      <v-text-field
        v-model="searchText"
        :label="$t('common.search')"
        prepend-inner-icon="search"
        tag="span"
        outlined
        dense
        hide-details
      />
    </v-row>
    <v-tabs-items v-model="tab">
      <v-tab-item v-for="{ id, content } in tabs" :key="id">
        <v-row v-if="id === 'pending'">
          <v-col cols="12">
            <div class="d-flex align-center">
              <v-icon color="black" class="mr-2">
                lightbulb_outline
              </v-icon><span>{{ $t('project.enrollments.acceptEnrollmentRequestInfo') }}</span>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="pa-0">
            <!-- Table -->
            <v-data-table v-if="content.length" :headers="headers" :items="content" :search="searchText"
                          :sort-by="['createdAt']"
                          :sort-desc="true"
                          dense class="mt-6 table--has-pointer"
                          @click:row="showDetail"
            >
              <template #[`item.logo`]="{ item }">
                <v-avatar>
                  <img :src="item.logo" class="pa-2">
                </v-avatar>
              </template>

              <template #[`item.staffName`]="{ item }">
                <multiple-labels :labels="item.staffNameLabels" />
              </template>
              <template #[`item.staffEmail`]="{ item }">
                <copy-text :text="item.staffEmail" />
              </template>
              <template #[`item.status`]="{ item }">
                <v-chip
                  v-if="item.status === 'pendingApproval'"
                  outlined label color="##F6F6F6" style="min-width: 5rem;"
                >
                  {{ $t('common.pending') }}
                </v-chip>
                <v-chip
                  v-else-if="item.status === 'pendingPayment'"
                  outlined label color="pending" style="min-width: 5rem;"
                >
                  {{ $t('project.enrollments.pendingPayment') }}
                </v-chip>
                <v-chip
                  v-else-if="item.status === 'rejected'"
                  outlined label color="error" style="min-width: 5rem;"
                >
                  {{ $t('common.rejected') }}
                </v-chip>
              </template>
              <template #[`item.createdAt`]="{ item }">
                <span v-text="formatDate(item.createdAt)" />
              </template>
              <template #[`item.actions`]="{ item }">
                <div class="d-flex">
                  <v-tooltip bottom>
                    <template #activator="{ on, attrs }">
                      <v-btn
                        v-if="item.status === 'pendingApproval'"
                        v-bind="attrs"
                        icon
                        color="success"
                        style="justify-content: start !important;"
                        v-on="on"
                        @click.stop.prevent="approve(item)"
                      >
                        <v-icon style="margin: 0 auto;">
                          check_circle_outline
                        </v-icon>
                      </v-btn>
                    </template>
                    <span v-text=" $t('project.enrollments.acceptEnrollmentRequest')" />
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template #activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        icon
                        color="error"
                        style="justify-content: start !important;"
                        v-on="on"
                        @click.stop.prevent="$confirm($t('project.enrollments.confirmReject'))
                          .then(confirm => confirm && reject(item))
                        "
                      >
                        <v-icon style="margin: 0 auto;">
                          highlight_off
                        </v-icon>
                      </v-btn>
                    </template>
                    <span v-text="item.status === 'pendingApproval'
                      ? $t('project.enrollments.rejectEnrollmentRequest')
                      : $t('project.enrollments.deleteEnrollmentRequest')"
                    />
                  </v-tooltip>
                </div>
              </template>
            </v-data-table>
            <empty-list v-else :content="$t('project.sections.enrollments')" />
          </v-col>
        </v-row>
      </v-tab-item>
    </v-tabs-items>
    <detail-club-enrollment-dialog
      v-if="organizationId && detailDialog && selectedClubEnrollment"
      :organization-id="organizationId"
      :project-id="projectId"
      :item="selectedClubEnrollment"
      :tab="tab"
      @close-dialog="detailDialog = false"
      @approve="approve(selectedClubEnrollment)"
      @reject="reject(selectedClubEnrollment)"
      @makePending="makePending(selectedClubEnrollment)"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import lodashGet from 'lodash/get'
import i18nCountries from '@/utils/mixins/i18nCountries'
import dateFilter from '@/utils/mixins/dateFilter'
import dataTables from '@/enums/dataTables'

export default {
  name: 'ClubEnrollmentList',
  components: {
    EmptyList: () => import('@/components/EmptyList'),
    ExportToCsv: () => import('@/components/ExportToCsv'),
    DetailClubEnrollmentDialog: () => import('@/modules/competition/DetailClubEnrollmentDialog'),
  },
  mixins: [i18nCountries, dateFilter],
  props: {
    organizationId: { type: String, required: true },
    projectId: { type: String, required: true },
  },
  data() {
    return {
      searchText: null,
      tab: null,
      selectedClubEnrollment: null,
      detailDialog: false,
    }
  },
  computed: {
    ...mapGetters({ project: 'project/data' }),
    ...mapGetters({ isUserManager: 'project/isUserManager' }),
    ...mapGetters({ rawEnrollments: 'competition/clubEnrollments' }),
    ...mapGetters({ isAcademy: 'project/isAcademy' }),
    ...mapGetters({ categories: 'academy/categories' }),
    enrollments: ({ rawEnrollments }) => rawEnrollments.map(enroll => ({
      ...enroll,
      staffNameLabels: enroll.staff.map(e => `${e.firstName} ${e.lastName}`),
      staffName: lodashGet(enroll, 'staff.0.firstName', ''),
      staffPhone: `+${lodashGet(enroll, 'staff.0.phone.code', '')} ${lodashGet(enroll, 'staff.0.phone.number', '')}`,
      staffEmail: lodashGet(enroll, 'staff.0.email', ''),
    })),
    headers({ tab, isUserManager }) {
      return [
        { text: this.$t('club.fields.logo'), value: 'logo' },
        { text: this.$t('club.fields.name'), value: 'name' },
        { text: this.$t('club.fields.staffName'), value: 'staffName' },
        { text: this.$t('club.fields.staffPhone'), value: 'staffPhone' },
        { text: this.$t('club.fields.staffEmail'), value: 'staffEmail' },
        { text: this.$t('club.fields.createdAt'), value: 'createdAt' },
        ...(tab === 0 && isUserManager ? [{ text: this.$t('club.fields.actions'), value: 'actions', filterable: false, sortable: false }] : []),
      ]
    },
    settingsDataTable() {
      return dataTables
    },
    filteredEnrollments({ enrollments, searchStatus }) {
      const activeRequests = enrollments.filter(enroll => ['pendingApproval', 'pendingPayment'].includes(enroll.status) || !enroll.status)
      if (searchStatus) {
        return activeRequests.filter(request => request.status === searchStatus)
      }
      return activeRequests
    },
    tabs({ enrollments, filteredEnrollments }) {
      return [
        {
          id: 'pending',
          name: this.$t('filters.pending'),
          content: filteredEnrollments,
        },
        {
          id: 'rejected',
          name: this.$t('filters.rejected'),
          content: enrollments.filter(enroll => enroll.status === 'rejected'),
        },
      ]
    },
  },
  created() {
    if (this.$route.query.clubEnrollmentId) this.showDetail(this.enrollments.find(e => e.id === this.$route.query.clubEnrollmentId))
  },
  methods: {
    async approve(enrollment) {
      const { organizationId, projectId } = this
      const { id: clubEnrollmentId } = enrollment
      await this.runAsync(() => this.$store.dispatch('competition/approveClubEnrollment', { organizationId, projectId, clubEnrollmentId }))
      this.detailDialog = false
    },
    async reject(enrollment) {
      const { organizationId, projectId } = this
      const { id: clubEnrollmentId } = enrollment
      await this.runAsync(() => this.$store.dispatch('competition/rejectClubEnrollment', { organizationId, projectId, clubEnrollmentId }))
      this.detailDialog = false
    },
    async makePending(enrollment) {
      const { organizationId, projectId } = this
      const { id: clubEnrollmentId } = enrollment
      await this.runAsync(() => this.$store.dispatch('competition/makePendingClubEnrollment', { organizationId, projectId, clubEnrollmentId }))
      this.detailDialog = false
    },
    showDetail(evt) {
      this.selectedClubEnrollment = evt
      this.detailDialog = true
    },
  },
}
</script>
